import './App.css';
import img from './uploads/bg-1.jfif';
import logo from './uploads/login.png';
function App() {
  return (
    <div
      style={ {
        backgroundColor: 'black',
        width: ' 100%',
        height: '100vh',
        position: 'relative',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
      } }
    >
      <img
        src={ img }
        alt='background'
        style={ {
          opacity: '0.2',
          width: '100%',
          height: '100vh',
          objectFit: 'cover',
          position: 'relative',
        } }
      />
      <div
        style={ {
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
        } }
      >
        <div
          style={ {
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            top: 100,
          } }
        >
          <img
            src={ logo }
            alt='Logo'
            style={ {
              width: '400px',
              objectFit: 'contain',
            } }
          />
        </div>
        <div
          style={ {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          } }
        >
          <svg
            className={ 'svg-ic' }
            width='380px'
            height='500px'
            viewBox='0 0 837 1045'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            xmlnsSketch='http://www.bohemiancoding.com/sketch/ns'
          >
            <g
              id='Page-1'
              stroke='none'
              stroke-width='1'
              fill='none'
              fill-rule='evenodd'
              sketchType='MSPage'
            >
              <path
                d='M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z'
                id='Polygon-1'
                stroke='#007FB2'
                stroke-width='6'
                sketchType='MSShapeGroup'
              ></path>
              <path
                d='M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z'
                id='Polygon-2'
                stroke='#EF4A5B'
                stroke-width='6'
                sketchType='MSShapeGroup'
              ></path>
              <path
                d='M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z'
                id='Polygon-3'
                stroke='#795D9C'
                stroke-width='6'
                sketchType='MSShapeGroup'
              ></path>
              <path
                d='M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z'
                id='Polygon-4'
                stroke='#F2773F'
                stroke-width='6'
                sketchType='MSShapeGroup'
              ></path>
              <path
                d='M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z'
                id='Polygon-5'
                stroke='#36B455'
                stroke-width='6'
                sketchType='MSShapeGroup'
              ></path>
            </g>
          </svg>
          <div className='message-box'>
            <h1
              style={ {
                marginBlockStart: 0,
                fontSize: 60,
                marginBottom: 80
              } }
            >
              WELCOME TO
            </h1>
            <h1 style={ { marginTop: 65 } }>DOMINO'S PIZZA AZERBAIJAN</h1>

            <div className='buttons-con'>
              <div className='action-link-wrap'>
                <p style={ { fontSize: 30 } }>
                  Click Here to visit
                  <p className={ "slide" }><a href={ 'https://dominospizza.az' }>https://dominospizza.az</a></p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={ {
          position: 'absolute',
          bottom: '10px',
        } }
      >
        <span
          className='btn-floating btn-fb mx-1 '
          style={ {
            textDecoration: 'none',
            color: '#9c9c9c',
            fontFamily: "'Open Sans', sans-serif",
          } }
        >
          Copyright &copy; { new Date().getFullYear() } Domino&rsquo;s Pizza
          Azerbaijan | Developed by
          <a
            className={ "footer" } 
            href='http://www.hitech-prime.com/'
            style={ {
              // color: 'white',
              // textDecoration: 'none',
              // fontWeight: 500,
            } }
            target='blank'
          >
            &nbsp;HiTech Prime
          </a>
        </span>
      </div>
    </div>
  );
}

export default App;
